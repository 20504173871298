import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
//Components
import Layout from "../components/Layout/Layout"
import BreadCrumb from "../components/BreadCrumb/BreadCrumb"
import PostSidebar from "../components/PostSidebar/PostSidebar"
//Utils
import TransformOembedToIframe from "../utils/TransformOembedToIframe"

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px;
`

const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`

const PostContent = styled.article`
  margin-top: 20px;
  width: 100%;

  h1 {
    font-family: "Gobold", sans-serif;
    line-height: 60px;
    font-weight: 100;
    letter-spacing: 4.5px;
    text-align: center;
  }

  iframe {
    width: 100%;
  }

  figcaption {
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.7rem;

    @media (max-width: 768px) {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    @media (min-width: 768px) {
      text-align: justify;
      direction: ltr;
      word-break: break-word;
    }
  }
  .content {
    padding: 0.5rem;
  }

  @media (min-width: 450px) {
    .content {
      padding: 1rem;
    }
  }

  @media (min-width: 768px) {
    .content {
      padding: 1.5rem;
    }
  }

  @media (min-width: 992px) {
    .content {
      padding: 2rem;
    }
  }
`

const ProyectTemplate = ({ data }) => (
  <Layout>
    <Wrapper>
      <BreadCrumb
        parent={{ uri: "/portafolio/all-proyects", title: "Portafolio" }}
      />
      <ContentWrapper>
        <PostContent>
          <h1 dangerouslySetInnerHTML={{ __html: data.item.title }} />
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: TransformOembedToIframe(data.item.content),
            }}
          />
        </PostContent>
      </ContentWrapper>
    </Wrapper>
  </Layout>
)

export default ProyectTemplate

export const PageQuery = graphql`
  query($id: String!) {
    item: wpProyecto(id: { eq: $id }) {
      title
      content
      author {
        node {
          name
        }
      }
      date(formatString: "DD MM YYYY")
      categoriasPortafolios {
        nodes {
          id
          name
          uri
          slug
        }
      }
    }
  }
`
